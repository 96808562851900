import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="mountain-hut-reservation"
export default class extends Controller {
  static targets = ["arrivalDate", "departureDate", "theForm"]

  connect() {

    console.log("mount hut reservation loaded")

    this.validateMountainHutReservation()

    var orderStartDate = document.getElementById('temp_start_date').value
    var orderEndDate = document.getElementById('temp_end_date').value

    var arrivalDate = this.arrivalDateTarget
    var departureDate = this.departureDateTarget

    mobiscroll.datepicker('#mountain_hut_reservation_dates', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        // inst.setVal([arrivalDate.value, departureDate.value]);
        console.log("arrivaldate " + arrivalDate.value)
        if (arrivalDate.value.length > 0) {
          inst.setVal([arrivalDate.value, departureDate.value]);
        } else {
          inst.setVal([orderStartDate, orderEndDate]);
          arrivalDate.value = orderStartDate
          departureDate.value = orderEndDate
        }

      },
      onChange: function (event, inst) {
        console.log(arrivalDate)
        arrivalDate.value = event.value[0]
        departureDate.value = event.value[1]
      }
    });
  }

  validateMountainHutReservation() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#mountain_hut_reservation_mountain_hut_id', [
          {
            rule: 'required',
            errorMessage: 'Hut is required'
          }
        ])
        .addField('#mountain_hut_reservation_dates', [
          {
            rule: 'required',
            errorMessage: 'dates are required'
          }
        ])
        .addField('#mountain_hut_reservation_people', [
          {
            rule: 'required',
            errorMessage: 'Places if required'
          }
        ])
        .addField('#mountain_hut_reservation_people', [
          {
            rule: 'required',
            errorMessage: 'Places if required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
