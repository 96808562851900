import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="trip-course"
export default class extends Controller {
  static targets = ["category", "courseType", "subCategory", "type", "level", "theForm"]
  static values = { category: Number }

  connect() {
    console.log("trip courses loaded")
    this.validateTripCourse()
  }

  loadSubCategories(current_selection) {
    console.log("current_selections: " + current_selection)
    console.log("loadSubCategories")
    var course_category = this.categoryTarget
    var sub_category = this.subCategoryTarget

    this.courseTypeTarget.innerHTML = ""

    if (course_category.value) {
      console.log("trip course selected")
      fetch("/course_categories/" + course_category.value + "/sub_categories.json", {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(r => r.text())
        .then(data => this.refreshDropdownValues(JSON.parse(data), this.subCategoryTarget))
        .then(this.loadLevels())
    }
  }

  loadCourseTypes() {
    this.loadLevels()
    console.log("loadCourseTypes")
    var sub_category= this.subCategoryTarget
    console.log("sub: " + sub_category.innerHTML)

    if (sub_category.value) {
      console.log("trip course selected")
      fetch("/course_sub_categories/" + sub_category.value + "/course_types.json", {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(r => r.text())
        .then(data => this.refreshDropdownValues(JSON.parse(data), this.courseTypeTarget))
    }
  }

  loadLevels() {
    console.log("loadCourseTypes")
    var sub_category= this.subCategoryTarget
    console.log("levels: " + this.levelTarget)

    if (sub_category.value) {
      console.log("trip course selected")
      fetch("/course_sub_categories/" + sub_category.value + "/levels.json", {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(r => r.text())
        .then(data => this.refreshDropdownValues(JSON.parse(data), this.levelTarget))
    }
  }

  refreshDropdownValues(data, the_target) {
    console.log('changing menu')
    console.log("target: " + this.courseTypeTarget)
    console.log(data)
    var items = data['items']
    // var selected = data['selected']
    var tempOptions = ''
    
    for(var i = 0; i < items.length; i++) {
      var opt = items[i]
      tempOptions += "<option value=\"" + opt.id + "\">" + opt.name + "</option>"
    }

    the_target.innerHTML = tempOptions
    console.log(data['selected'])
    // this.tripCourseDateTarget.value = selected.id
  }

  validateTripCourse() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#trip_course_translations_attributes_0_name', [
          {
            rule: 'required',
            errorMessage: 'Name is required'
          }
        ])
        .addField('#trip_course_course_category_id', [
          {
            rule: 'required',
            errorMessage: 'Category is required'
          }
        ])
        .addField('#trip_course_course_sub_category_id', [
          {
            rule: 'required',
            errorMessage: 'Sub Category is required'
          }
        ])
        .addField('#trip_course_course_type_id', [
          {
            rule: 'required',
            errorMessage: 'Course type is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
