import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trip-cost"
export default class extends Controller {
  static targets = ["when"]

  connect() {
    console.log('trip costs connected')
    var when = this.whenTarget

    var orderStartDate = document.getElementById('temp_start_date')

    mobiscroll.datepicker(when, {
      controls: ['calendar'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!when.value) {
          console.log("setting order dates as global")
          inst.setVal(orderStartDate.value);
        } else {
          console.log("existing transfer time: " + when.value)
          inst.setVal(when.value);
        }
      },
    });

  }
}
