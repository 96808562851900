import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import JustValidate from 'just-validate';

let debounce = require('lodash/debounce');
let throttle = require('lodash/throttle');

// Connects to data-controller="payment"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("payment loaded")
    this.datepicker()
    this.validatePayment()
  }

  initialize() {
    this.contactSearch = debounce(this.contactSearch, 500).bind(this)
    console.log("initialized search")
  }

  contactTypeSelector(e) {
    var selector = document.getElementById('payment_contact_type')
    var existingContact = document.getElementById('existing_contact')
    var newContact = document.getElementById('new_contact')

    console.log(selector.value)

    if (selector.value == 'existing_contact') {
      existingContact.classList.remove('hidden');
      newContact.classList.add('hidden');
    }

    if (selector.value == 'new_contact') {
      newContact.classList.remove('hidden');
      existingContact.classList.add('hidden')
    }
  }

  contactSearch() {
    var search = document.getElementById('payment_search_contact')
    var order = document.getElementById('payment_order_id')

    console.log(search.value)
    fetch('/contacts/search?q=' + search.value + '&o=' + order.value)
  }

  datepicker() {
    mobiscroll.datepicker('.datepicker', {
      controls: ['calendar'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1
    });
  }

  validatePayment() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#payment_payment_amount', [
          {
            rule: 'required',
            errorMessage: 'Payment amount is required'
          }
        ])
        .addField('#payment_payment_method_id', [
          {
            rule: 'required',
            errorMessage: 'Payment method is required'
          }
        ])
        .addField('#payment_payment_made', [
          {
            rule: 'required',
            errorMessage: 'Payment made is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }

}
