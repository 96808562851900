import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="transfers"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("transfer form loaded")

    this.loadMobi()
    this.validateTransfer()
  }

  loadMobi() {
    console.log('mobi loading')
    var orderStartDate = document.getElementById('temp_start_date')
    var transferDateTime = document.getElementById('transfer_departure_arrival_time')

    mobiscroll.datepicker('#transfer_departure_arrival_time', {
      controls: ['calendar', 'time'],
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'Pick Up',
      rangeEndLabel: 'Drop Off',
      dateFormat: 'DDD, D MMM YYYY',
      timeFormat: 'HH:mm',
      separator: ' - ',
      stepMinute: 5,
      display: 'anchored',
      firstDay: 1,
      // onInit: function (event, inst) {
      //   if (!transferDateTime.value) {
      //     console.log("existing transfer time: " + transferDateTime.value)
      //     inst.setVal(orderStartDate.value);
      //   }
      // },
    });
  }

  validateTransfer() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#transfer_participant_id', [
          {
            rule: 'required',
            errorMessage: 'Participant is required'
          }
        ])
        .addField('#transfer_passengers', [
          {
            rule: 'required',
            errorMessage: 'Passengers required'
          }
        ])
        .addField('#transfer_from', [
          {
            rule: 'required',
            errorMessage: 'From is required'
          }
        ])
        .addField('#transfer_to', [
          {
            rule: 'required',
            errorMessage: 'To is required'
          }
        ])
        .addField('#transfer_departure_arrival_time', [
          {
            rule: 'required',
            errorMessage: 'Date/times required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}

