import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropzone"
export default class extends Controller {

  connect() {
    this.bindEvents();
    console.log("dropzone loaded")
  }
  
  bindEvents(event) {

  }

}
