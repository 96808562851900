import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="centre-clicks"
export default class extends Controller {
  static targets = ["image", "x", "y", "pixel_x", "pixel_y", "bullseye"]

  connect() {
    console.log("centre-images connected")
    this.setIconPosition();
  }

  getCoordinates(click) {
    const offset = this.imageTarget.getBoundingClientRect()

    const offsetX = click.pageX - offset.left - 12;
    const offsetY = click.pageY - offset.top - 12 - window.pageYOffset;

    const focusX = (offsetX/click.target.width - .5)*2;
    const focusY = (offsetY/click.target.height - .5)*-2;

    this.xTarget.value = focusX;
    this.yTarget.value = focusY;

    this.pixel_xTarget.value = offsetX;
    this.pixel_yTarget.value = offsetY;

    this.setIconPosition();
  }

  setIconPosition() {
    this.bullseyeTarget.style.left = this.pixel_xTarget.value + "px";
    this.bullseyeTarget.style.top = this.pixel_yTarget.value + "px";
  }
}
