import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="meeting"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log('meeting connected')
    this.validateMeeting()

    mobiscroll.datepicker('#meeting_when', {
      controls: ['calendar', 'time'],
      touchUi: true,
      showWeekNumbers: false,
      dateFormat: 'DDD, DD MMM YYYY',
      timeFormat: 'HH:mm',
      separator: ' - ',
      stepMinute: 15,
      display: 'anchored',
      firstDay: 1
    });
  }

  validateMeeting() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#meeting_when', [
          {
            rule: 'required',
            errorMessage: 'Date/time is required'
          }
        ])
        .addField('#meeting_where', [
          {
            rule: 'required',
            errorMessage: 'Where is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
