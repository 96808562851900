// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "trix"
import "trix/dist/trix.css"
import "@rails/actiontext"

import "@hotwired/turbo-rails"
Turbo.session.drive = false

import dropzone from "dropzone"
window.dropzone = dropzone

import * as mobiscroll from "@mobiscroll/javascript/dist/esm5/mobiscroll.javascript.min.js"
window.mobiscroll = mobiscroll
// import "@mobiscroll/javascript/dist/css/mobiscroll.scss"

import "controllers"
import "../stylesheets/application.sass"

Rails.start()
ActiveStorage.start()