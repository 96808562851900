import { Controller } from "@hotwired/stimulus"
import moment from "moment"
import JustValidate from 'just-validate';

// Connects to data-controller="order-trip-course"
export default class extends Controller {
  // static values = { startDate: String, endDate: String }
  static targets = ["theForm", "orderStartDate", "orderEndDate", "tripCourse", "privateDates", "privateCourseDates", "privateCheckbox", "private", "courseDates", "courseDatesSelector", "tripCourseDate", "tempSelected", "price"]

  connect() {
    console.log(this.tripCourseTarget.value)

    if (this.hasTheFormTarget) {
      this.validateOrderTripCourse()
    }

    if (this.tripCourseTarget.value == null && this.courseDatesTarget == null) {
      console.log("trip course: " + this.tripCourseTarget.value)
      this.loadDates()
    }

    var orderStartDate = document.getElementById('temp_start_date')
    var orderEndDate = document.getElementById('temp_end_date')

    var startDate = this.orderStartDateTarget // document.getElementById('order_trip_course_start_date')
    var endDate = this.orderEndDateTarget // document.getElementById('order_trip_course_end_date')
    var datePicker = this.privateCourseDatesTarget // '#order_trip_course_private_course_dates'
    
    mobiscroll.datepicker(datePicker, {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      weekStart: 'MO',
      firstDay: 1,
      onInit: function (event, inst) {
        if (orderStartDate && !startDate.value) {
          console.log("setting order dates as global")
          inst.setVal([orderStartDate.value, orderEndDate.value]);
        } else {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        startDate.value = event.value[0]
        endDate.value = event.value[1]
      }
    });
  }

  hideDatePicker() {
    var private_dates = this.privateDatesTarget // document.getElementById('private_dates');
    var privateCheckbox = this.privateCheckboxTarget // document.getElementById('order_trip_course_private');
    var courseDate = this.courseDatesTarget // document.getElementById('course_dates');

    if (privateCheckbox.checked == true) {
      console.log("private is checked")
      private_dates.classList.remove("hidden")
      courseDate.classList.add("hidden")
      this.tripCourseDateTarget.value = '';
    } else {
      console.log("private is not checked")
      private_dates.classList.add("hidden")
      courseDate.classList.remove("hidden")
    }
  }

  loadDates() {
    console.log("loading dates")
    var trip_course = this.tripCourseTarget.value // document.getElementById('order_trip_course_trip_course_id').value
    var date_selected = this.tempSelectedTarget.value

    console.log("date selected: " + date_selected)

    if (trip_course) {
      fetch("/trip_courses/" + trip_course + "/get_course_dates.json?date_selected=" + date_selected, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(r => r.text())
        .then(data => this.refreshDropdownValues(JSON.parse(data)))
        .then(data => this.loadDatesPrice(data))
    }
  }

  loadDatesPrice() {
    console.log("loading dates price")
    var trip_course_date = this.tripCourseDateTarget.value // document.getElementById('order_trip_course_course_date_id').value
    console.log(trip_course_date)

    if (trip_course_date) {
      console.log("trip course selected")
      fetch("/course_dates/" + trip_course_date + "/get_price", {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(r => r.text())
        .then(data => this.updatePrice(JSON.parse(data)))
    }
  }

  refreshDropdownValues(data) {
    console.log('changing menu')
    console.log(data)
    var dates = data['dates']
    var tempOptions = ''
    
    for(var i = 0; i < dates.length; i++) {
      var opt = dates[i]
      tempOptions += "<option value=\"" + opt.id + "\">" + opt.name + "</option>"
    }

    this.tripCourseDateTarget.innerHTML = tempOptions
    console.log(data['selected'])
    this.tripCourseDateTarget.value = this.tempSelectedTarget.value
  }

  updatePrice(data) {
    var course_date = data['course_date']
    console.log(course_date.price)
    this.priceTarget.value = course_date.price
  }

  validateOrderTripCourse() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#order_trip_course_participant_id', [
          {
            rule: 'required',
            errorMessage: 'Participant is required'
          }
        ])
        .addField('#order_trip_course_trip_course_id', [
          {
            rule: 'required',
            errorMessage: 'Trip course is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }

}
