import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="navigation"
export default class extends Controller {
  static targets = ["navHamburger"]

  connect() {
    console.log('navigation loaded')
  }

  clickedNav() {
    console.log('nav clicked')
    var x =  document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }


}
