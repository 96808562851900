import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="insurance"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("insurance connected")
    this.validateInsurance()

    var orderStartDate = document.getElementById('temp_start_date')
    var insuranceDate = document.getElementById('insurance_start_date')

    mobiscroll.datepicker(insuranceDate, {
      controls: ['calendar'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!insuranceDate.value) {
          console.log("setting order dates as global")
          inst.setVal(orderStartDate.value);
        } else {
          console.log("existing transfer time: " + insuranceDate.value)
          inst.setVal(insuranceDate.value);
        }
      },
    });

    mobiscroll.datepicker('#insurance_purchase_date', {
      controls: ['calendar'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      min: '#{Date.now}',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1
    });

  }

  validateInsurance() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#insurance_participant_id', [
          {
            rule: 'required',
            errorMessage: 'Participant is required'
          }
        ])
        .addField('#insurance_start_date', [
          {
            rule: 'required',
            errorMessage: 'Start date is required'
          }
        ])
        .addField('#insurance_days', [
          {
            rule: 'required',
            errorMessage: 'Days is required'
          }
        ])
        .addField('#insurance_price', [
          {
            rule: 'required',
            errorMessage: 'Price is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
