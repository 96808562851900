import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

var chartObject = null

// Connects to data-controller="statistics"
export default class extends Controller {
  static targets = ["start", "end", "type", "chart"]

  connect() {
    console.log("statistics controller loaded")
    
    this.dateSearch()

    this.createChart()
  }

  checkChanged() {
    console.log("Changed")

    if (this.startTarget.value != "" && this.endTarget.value != "" && this.typeTarget.value != "") {
      console.log("Start:", this.startTarget.value)
      console.log("End:", this.endTarget.value)
      console.log("Type:", this.typeTarget.value)
      this.getData()
    }
  }

  getData() {
    console.log("Getting data")
    
    var params = {
      start: this.startTarget.value,
      end: this.endTarget.value,
      type: this.typeTarget.value,
    }

    fetch('/' + this.typeTarget.value + '/stats.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(params)
    })
    .then(r => r.text())
    .then(data => this.renderChart(JSON.parse(data)))
  }

  dateSearch() {
    var start = this.startTarget
    var end = this.endTarget

    mobiscroll.datepicker(start, {
      select: 'range',
      dateFormat: 'DD/MM/YYYY',
      startInput: start,
      endInput: end
    });

    end.addEventListener('click', function () {
      document.querySelector('.mbsc-range-end').click();
    });
  }

  createChart() {
    var theChart = this.chartTarget
    
    chartObject = new Chart(
      theChart,
      {
        options: {
          animation: true,
          plugins: {
            legend: {
              display: true
            },
            tooltip: {
              enabled: true
            }
          }
        },
      }
    );
  }

  renderChart(stats) {
    console.log("Rendering chart")
    console.log(stats.enquiries.data)

    chartObject.data = {
      labels: stats.enquiries.keys,
      datasets: stats.enquiries.data
    }

    chartObject.update()
  }

}
