import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="course-dates"
export default class extends Controller {
  static targets = ["add", "template", "start", "end"]

  connect() {
    this.rangePicker()
  }

  add(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.addTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = 1
    item.style.display = 'none'
  }

  rangePicker() {
    if (this.hasStartTarget && this.hasEndTarget) {
      var startDate = this.startTarget
      var endDate = this.endTarget
    }

    mobiscroll.datepicker('.range_picker', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'DD/MM/YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      weekStart: 'MO',
      firstDay: 1,
      onInit: function (event, inst) {
        if (startDate && endDate) {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        var selector = document.getElementById(inst._el.id)
        var startDate = selector.closest('.fields_holder').querySelector('.start_date')
        var endDate = selector.closest('.fields_holder').querySelector('.end_date')

        startDate.value = event.value[0]
        endDate.value = event.value[1]
      },
      // onActiveDateChange: function (event, inst) {
      //   var selector = document.getElementById(inst._el.id)
      //   var startDate = selector.closest('.fields_holder').querySelector('.start_date').value
      //   var endDate = selector.closest('.fields_holder').querySelector('.end_date').value

      //   if (startDate && endDate && !selector.value) {
      //     console.log("original dates")
      //     inst.setVal([startDate, endDate]);
      //   } else {
      //     inst.setVal([startDate.value, endDate.value]);
      //   }
      // },
    });
  }
}
