import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="guide-payment"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log('guide payment connected')
    this.validateGuidePayment()

    mobiscroll.datepicker('#guide_payment_payment_date', {
      controls: ['calendar'],
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
    });
  }

  validateGuidePayment() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#guide_payment_invoice_ref', [
          {
            rule: 'required',
            errorMessage: 'Guide invoice reference required'
          }
        ])
        .addField('#guide_payment_amount', [
          {
            rule: 'required',
            errorMessage: 'Amount is required'
          }
        ])
        .addField('#guide_payment_payment_date', [
          {
            rule: 'required',
            errorMessage: 'Payment date is required'
          }
        ])
        .addRequiredGroup(
          '.guide_bookings_paid',
          'You should select at least one job'
        )
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
