import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="lift-passes"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("lift passes connected")
    this.validateLiftPasses()

    var orderStartDate = document.getElementById('temp_start_date')
    var liftDate = document.getElementById('lift_pass_start_date')

    mobiscroll.datepicker(liftDate, {
      controls: ['calendar'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!liftDate.value) {
          console.log("setting order dates as global")
          inst.setVal(orderStartDate.value);
        } else {
          console.log("existing transfer time: " + liftDate.value)
          inst.setVal(liftDate.value);
        }
      },
    });
  }

  validateLiftPasses() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#lift_pass_participant_id', [
          {
            rule: 'required',
            errorMessage: 'Participant is required'
          }
        ])
        .addField('#lift_pass_ticket_type_id', [
          {
            rule: 'required',
            errorMessage: 'Lift pass type is required'
          }
        ])
        .addField('#lift_pass_start_date', [
          {
            rule: 'required',
            errorMessage: 'Start date is required'
          }
        ])
        .addField('#lift_pass_days', [
          {
            rule: 'required',
            errorMessage: 'Days is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }


}
