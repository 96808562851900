import { Controller } from "@hotwired/stimulus"
import interact from 'interactjs'

// Connects to data-controller="dragdrop"
export default class extends Controller {
  connect() {
    console.log('dragdrop connected')
    this.draggable()
  }

  draggable() {
    const position = { x: 0, y: 0 }

    interact('.draggable').draggable({
      listeners: {
        start (event) {
          console.log(event.type, event.target)
        },
        move (event) {
          position.x += event.dx
          position.y += event.dy

          event.target.style.transform =
            `translate(${position.x}px, ${position.y}px)`
        },
      }
    })
  }
}
