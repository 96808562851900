import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="extras"
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("extras connected")
    this.validateExtra()

    mobiscroll.datepicker('#insurance_start_date', {
      controls: ['calendar'],
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      min: '#{Date.now}',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1
    });

  }

  validateExtra() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#extra_participant_id', [
          {
            rule: 'required',
            errorMessage: 'Participant is required'
          }
        ])
        .addField('#extra_description', [
          {
            rule: 'required',
            errorMessage: 'Description is required'
          }
        ])
        .addField('#extra_price', [
          {
            rule: 'required',
            errorMessage: 'Price is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
