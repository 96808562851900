import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="order-guide"
export default class extends Controller {
  static values = { startDate: String, endDate: String, datePaid: String }
  static targets = ["dates", "startDate", "endDate", "datePaid", "theForm", "warning", "guide"]

  connect() {
    console.log("order guides loaded")

    this.validateGuideBooking()

    var startDate = this.startDateTarget
    var endDate = this.endDateTarget

    if (this.hasDatePaidTarget) {
      this.datePaidField()
    }

    mobiscroll.datepicker(this.datesTarget, {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!startDate) {
          console.log("setting order dates as global")
          inst.setVal([startDate.value, endDate.value]);
        } else {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        console.log(startDate)
        startDate.value = event.value[0]
        endDate.value = event.value[1]
      }
    });


  }

  datePaidField() {

    var datePaid = this.datePaidTarget

    mobiscroll.datepicker(this.datePaidTarget, {
      controls: ['calendar'],
      dateFormat: 'MMMM DD, YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      onInit: function (event, inst) {
        if (!datePaid) {
          console.log("setting order dates as global")
          inst.setVal(datePaid.value);
        } else {
          inst.setVal(datePaid.value);
        }
      },
    });
  }

  checkDates() {
    var params = {
      guide: this.guideTarget.value,
      start_date: this.startDateTarget.value,
      end_date: this.endDateTarget.value
    }

    if (this.checkString(params['guide']) == true && this.checkString(params['start_date']) == true && this.checkString(params['end_date']) == true) {
      fetch('/guide_bookings/check_guide_dates.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(params)
      })
      .then(r => r.text())
      .then(data => this.splitArray(JSON.parse(data).data))
    }
  }

  splitArray(data) {
    var jobArray = []
    for (let i = 0; i < data.length; i++) {
      jobArray.push(data[i].title);
    }
    console.log(jobArray)
    this.warningTarget.innerHTML = jobArray.join(", ")
  }

  checkString(myStr) {
    if (myStr === null || myStr.trim() === "") {
      return false
    } else {
      return true
    }
  }

  validateGuideBooking() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#guide_booking_guide_id', [
          {
            rule: 'required',
            errorMessage: 'Guide is required'
          }
        ])
        .addField('#guide_booking_dates', [
          {
            rule: 'required',
            errorMessage: 'Dates are required'
          }
        ])
        .addField('#guide_booking_pay', [
          {
            rule: 'required',
            errorMessage: 'Pay required'
          }
        ])

        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
