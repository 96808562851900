import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';

// Connects to data-controller="job-offer"
export default class extends Controller {
  static values = { startDate: String, endDate: String }
  static targets = ["startDate", "endDate", "theForm"]

  connect() {
    console.log("job offers loaded")

    this.validateJobOffer()

    var startDate = this.startDateTarget
    var endDate = this.endDateTarget

    mobiscroll.datepicker('#job_offer_dates', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'MMMM DD, YYYY', 
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!startDate) {
          console.log("setting order dates as global")
          inst.setVal([startDate.value, endDate.value]);
        } else {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        console.log(startDate)
        startDate.value = event.value[0]
        endDate.value = event.value[1]
      }
    });

    console.log(startDate)

  }

  validateJobOffer() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#job_offer_guide_group_id', [
          {
            rule: 'required',
            errorMessage: 'Guide group is required'
          }
        ])
        .addField('#job_offer_title', [
          {
            rule: 'required',
            errorMessage: 'Title is required'
          }
        ])
        .addField('#job_offer_dates', [
          {
            rule: 'required',
            errorMessage: 'Dates are required'
          }
        ])
        .addField('#job_offer_no_of_clients', [
          {
            rule: 'required',
            errorMessage: 'No. of clients is required'
          }
        ])
        .addField('#job_offer_pay', [
          {
            rule: 'required',
            errorMessage: 'Pay is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }
}
