import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="panel"
export default class extends Controller {
  static targets = ["paneltype", "photospanel", "coursespanel"]

  connect() {
    console.log("panel connected")
  }

  changeOptions() {
    var kind = this.paneltypeTarget.value
    if (kind == 'photos') return this.photosPanel()
    if (kind == 'courses') return this.coursesPanel()
    if (kind == 'normal') return this.normalPanel()
  }

  photosPanel() {
    this.photospanelTarget.classList.remove("hidden")
    this.coursespanelTarget.classList.add("hidden")
  }

  coursesPanel() {
    this.coursespanelTarget.classList.remove("hidden")
    this.photospanelTarget.classList.add("hidden")
  }

  normalPanel() {
    this.coursespanelTarget.classList.add("hidden")
    this.photospanelTarget.classList.add("hidden")
  }
}
