import { Controller } from "@hotwired/stimulus"
// import * as mobiscroll from "@mobiscroll/javascript"
var calendar
export default class extends Controller {
  static targets = ["startDate", "endDate", "datePaid"]
  static values = {
    guidedate: String
  }
  
  connect() {
    console.log("initialising calendar")

    mobiscroll.setOptions({
      theme: 'ios',
      themeVariant: 'light'
    });

    (function () {

      var formatDate = mobiscroll.util.datetime.formatDate;
      var currentEvent;
      var timer;
      var tooltip = document.getElementById('custom-event-tooltip-popup');
      // var urls = ['/course_dates/guaranteed.json', '/order_trip_courses/privates.json', '/meetings/calendar.json', '/mountain_hut_reservations/reservations.json']

      var header = document.getElementById('tooltip-event-header');
      var data = document.getElementById('tooltip-event-title');
      var participants = document.getElementById('participants');
      var guides = document.getElementById('guides');
      var huts = document.getElementById('huts');

      const calendarObject = document.getElementById('calendar')

      calendar = mobiscroll.eventcalendar(calendarObject, {
        view: {
          calendar: {
            endDay: 7,
            type: calendarObject.dataset.type,
            weekNumbers: true
          }
        },
        colors: [{
          start: Date.now(),
          end: Date.now(),
          background: '#e2ecf7'
        }],
        firstDay: 1,
        height: calendarObject.dataset.height,
        clickToCreate: false,
        dragToCreate: false,
        showEventTooltip: false,
        onPageLoading: function (event, inst) {
          inst._events = []

          console.log(event.firstDay)

          var year = event.firstDay.getFullYear(),
            month = event.firstDay.getMonth() + 1,
            day = event.firstDay.getDate();
          var urls = calendarObject.dataset.urls.split(' ')
          console.log(urls)

          loadData(urls, year, month, day, calendarObject.dataset.overlap, inst);
        },
      });

      function loadData(urls, year, month, day, period, inst) {
        urls.forEach(function (url) {
          var dateSent = year + '-' + month + '-' + day
          mobiscroll.util.http.getJson(url + '?d=' + dateSent + '&p=' + period, function (data) {

            for (var i = 0; i < data.length; i++) {
              var ev = {
                id: data[i].id,
                start: data[i].start,
                end: data[i].end || '',
                allDay: data[i].allDay,
                title: data[i].title,
                course_title: data[i].course_title,
                color: data[i].color,
                participants: data[i].participants,
                guides: data[i].guides,
                huts: data[i].huts,
                url: data[i].url,
                resource: data[i].resource
              };
              inst.addEvent(ev);
            }

            // mobiscroll.toast({
            //   message: 'New events loaded'
            // });

            console.log(inst._events)

          }, 'json');
        });
      }

    })();
  }

  changeDate(event) {
    console.log(event)
    var orderDate = event.target.dataset.guidedate
    calendar.navigate(new Date(orderDate));
  }

}
