import { Controller } from "@hotwired/stimulus"
// import * as mobiscroll from "@mobiscroll/javascript"
let debounce = require('lodash/debounce');



export default class extends Controller {
  static targets = ["startDate", "endDate", "datePaid"]
  static values = {
    guidedate: String
  }

  connect() {
    console.log("initialising calendar")

    mobiscroll.registerComponent(mobiscroll.CalendarNext);
    mobiscroll.registerComponent(mobiscroll.CalendarPrev);
    mobiscroll.registerComponent(mobiscroll.CalendarToday);
    mobiscroll.registerComponent(mobiscroll.CalendarNav);

    mobiscroll.setOptions({
      locale: mobiscroll.localeEnGB,  // Specify language like: locale: mobiscroll.localePl or omit setting to use default
      theme: 'ios',                   // Specify theme like: theme: 'ios' or omit setting to use default
      themeVariant: 'light'
    });

    var cookie = getCookie("firstDay");

    var formatDate = mobiscroll.util.datetime.formatDate;
    var currentEvent;
    var timer;
    var tooltip = document.getElementById('custom-event-tooltip-popup');
    // var urls = ['/course_dates/guaranteed.json', '/order_trip_courses/privates.json', '/meetings/calendar.json', '/mountain_hut_reservations/reservations.json']

    var header = document.getElementById('tooltip-event-header');
    var data = document.getElementById('tooltip-event-title');
    var participants = document.getElementById('participants');
    var guides = document.getElementById('guides');
    var huts = document.getElementById('huts');
    var meeting = document.getElementById('meeting');
    var hut = document.getElementById('hut');
    var calendars = [{ id: 1, name: 'guaranteed', checked: true, color: '#328e39' }, { id: 2, name: 'privates', checked: true, color: '#328e39' }, { id: 3, name: 'meetings', checked: true, color: '#328e39' }, { id: 4, name: 'huts', color: '#328e39' }, { id: 5, name: 'not_guaranteed', color: '#328e39' }]
    var events = []
    var selected = [1, 2, 3];
    var calendar

    const calendarObject = document.getElementById('chamex_calendar')

    // set up calendar
    calendar = this.calendarSetup(calendar, calendarObject, calendars, cookie, setCookie, loadData, toolTip);

    // tooltip popup
    var tooltip = mobiscroll.popup('#custom-event-tooltip-popup', {
      display: 'anchored',
      touchUi: true,
      showOverlay: false,
      contentPadding: false,
      closeOnOverlayClick: false,
      width: 350,
      onInit: function () {
        tooltip.addEventListener('mouseenter', function (e) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }
        });

        tooltip.addEventListener('mouseleave', function () {
          timer = setTimeout(function () {
            tooltip.close();
          }, 200);
        });
      }
    });

    // participants list
    function participantsList(list) {
      if (typeof list === 'undefined' || list.length == 0) {
        return ""
      }

      var tempList = '<h4>Participants</h4><table class="table-auto w-full mb-2">'
      for (const p in list) {
        tempList += '<tr class="text-sm"><td class="w-1/2"><a href="' + list[p].order + '">' + list[p].name + '</a></td><td class="w-1/2">' + list[p].mobile + '</td></tr>'
      }
      tempList += '</table>'
      return tempList
    }

    // Guides list
    function guidesList(list) {
      if (typeof list === 'undefined' || list.length == 0) {
        return ""
      }

      var tempList = '<h4>Guides</h4><table class="table-auto w-full mb-2">'
      for (const p in list) {
        tempList += '<tr class="text-sm"><td class="w-1/2">' + list[p].name + '</td><td class="w-1/2">' + list[p].mobile + '</td></tr>'
      }
      tempList += '</table>'
      return tempList
    }

    // Huts list
    function hutsList(list) {
      if (typeof list === 'undefined' || list.length == 0) {
        return ""
      }

      var tempList = '<h4>Huts</h4><table class="table-auto w-full mb-2">'
      for (const p in list) {
        tempList += '<tr class="text-sm"><td class="w-1/2">' + list[p].hut + '</td><td class="w-1/2">' + list[p].date + '</td></tr>'
      }
      tempList += '</table>'
      return tempList
    }

    // Meeting details
    function meetingDetails(event) {
      if (event.name != "meetings") {
        return ""
      }

      var meeting = '<h4>Meeting</h4><table class="table-auto w-full mb-2">'
      meeting += '<tr class="text-sm"><td class="w-1/2">Where</td><td class="w-1/2">' + event.where + '</td></tr>'
      // meeting += '<tr class="text-sm"><td class="w-1/2">When</td><td class="w-1/2">' + event.start + '</td></tr>'
      meeting += '<tr class="text-sm"><td class="w-1/2">Notes</td><td class="w-1/2">' + event.notes + '</td></tr>'
      meeting += '</table>'
      return meeting
    }

    // Hut details
    function hutDetails(event) {
      if (event.name != "huts") {
        return ""
      }

      var hut = '<tr class="text-sm"><td colspan="2" class="leading-none">' + event.parent + '</td></tr>'
      hut += '<h4>Hut</h4><table class="table-auto w-full">'
      hut += '<tr class="text-sm"><td class="w-1/2">Guardian</td><td class="w-1/2">' + event.guardian + '</td></tr>'
      hut += '<tr class="text-sm"><td class="w-1/2">Phone</td><td class="w-1/2">' + event.phone + '</td></tr>'
      hut += '<tr class="text-sm"><td class="w-1/2">Notes</td><td class="w-1/2">' + event.notes + '</td></tr>'
      hut += '</table>'
      return hut
    }

    // Tooltip
    function toolTip(args, inst) {
      var event = args.event;
      var eventTime = formatDate('hh:mm A', new Date(event.start)) + ' - ' + formatDate('hh:mm A', new Date(event.end));
      var button = {};

      currentEvent = event;

      header.style.backgroundColor = event.color;
      data.innerHTML = event.title;

      participants.innerHTML = participantsList(event.participants)
      guides.innerHTML = guidesList(event.guides)
      huts.innerHTML = hutsList(event.huts)
      meeting.innerHTML = meetingDetails(event)
      hut.innerHTML = hutDetails(event)

      if (event.course_title !== undefined) {
        course_title.innerHTML = event.course_title;
      } else {
        course_title.innerHTML = ""
      }

      clearTimeout(timer);
      timer = null;

      tooltip.setOptions({ anchor: args.domEvent.target });
      tooltip.open();
    }

    // Load data
    function loadData(urls, year, month, day, period, inst) {
      events = [];

      urls.forEach(function (url) {
        var dateSent = year + '-' + month + '-' + day

        mobiscroll.util.http.getJson(url + '?d=' + dateSent + '&p=' + period, function (data) {
          for (var i = 0; i < data.length; i++) {
            events.push({
              cssClass: data[i].cssClass,
              id: data[i].id,
              start: data[i].start,
              end: data[i].end || '',
              allDay: data[i].allDay,
              title: data[i].title,
              course_title: data[i].course_title,
              color: data[i].color,
              participants: data[i].participants,
              guides: data[i].guides,
              huts: data[i].huts,
              url: data[i].url,
              resource: data[i].resource,
              name: data[i].name,
              when: data[i].when,
              where: data[i].where,
              notes: data[i].notes,
              phone: data[i].phone,
              guardian: data[i].guardian,
              parent: data[i].parent
            });
          }
          filterEvents(inst);
        }, 'json');
      });

    }

    // set cookie
    function setCookie(firstDay) {
      document.cookie = "firstDay=" + firstDay;
    }

    // get cookie
    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    function filterSelection(inst) {
      selected = [];
      var checkboxes = document.querySelectorAll('.md-header-filter:checked')

      for (var i = 0; i < checkboxes.length; i++) {
        selected.push(parseInt(checkboxes[i].value));
      }

      filterEvents(inst);
    }

    function filterEvents(inst) {
      var evs = [];
      for (var i = 0; i < events.length; ++i) {
        var item = events[i];
        if (selected.indexOf(item.resource) > -1) {
          evs.push(item);
        }
      }
      inst.setEvents(evs);
    }

    document.querySelectorAll('.md-header-filter').forEach(function (elm) {
      elm.addEventListener('change', function (ev) {
        filterSelection(calendar)
      });
    });

  }

  // Change date
  changeDate(event) {
    var orderDate = event.target.dataset.guidedate
    calendar.navigate(new Date(orderDate));
  }

  calendarSetup(calendar, calendarObject, calendars, cookie, setCookie, loadData, toolTip) {
    calendar = mobiscroll.eventcalendar(calendarObject, {
      view: {
        calendar: {
          endDay: 7,
          type: calendarObject.dataset.type,
          weekNumbers: true,
          labels: 'all'
        }
      },
      renderHeader: function () {
        var header = '<div mbsc-calendar-nav class="md-header-filter-nav"></div>';

        header += '<div class="md-header-filter-controls">';
        for (var i = 0; i < calendars.length; ++i) {
          var res = calendars[i];
          header += '<label>' +
            // '<img class="md-header-filter-img" src="' + res.img + '"/>' +
            '<span class="md-header-filter-name md-header-filter-name-' + res.id + ' uppercase text-sm">' + res.name + '</span>' +
            '<input type="checkbox" mbsc-segmented name="resource" class="hidden md-header-filter" value=' +
            res.id + ' ' + (res.checked ? 'checked' : '') + '>' +
            '</label>';
        }
        header += '</div>';

        header += '<div mbsc-calendar-prev class="md-header-filter-prev"></div>' +
          '<div mbsc-calendar-today class="md-header-filter-today"></div>' +
          '<div mbsc-calendar-next class="md-header-filter-next"></div>';
        return header;
      },
      colors: [{
        start: Date.now(),
        end: Date.now(),
        background: '#e2ecf7'
      }],
      firstDay: 1,
      selectedDate: new Date(cookie),
      clickToCreate: false,
      dragToCreate: false,
      showEventTooltip: false,
      onPageLoading: function (event, inst) {
        setCookie(event.month);

        var year = event.firstDay.getFullYear(), month = event.firstDay.getMonth() + 1, day = event.firstDay.getDate();
        var urls = calendarObject.dataset.urls.split(' ');

        loadData(urls, year, month, day, calendarObject.dataset.overlap, inst);
      },
      onEventClick: function (data, inst) {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          window.location.href = data.event.url;
        } else {
          toolTip(data, inst);
        }
      },
      onEventHoverOut: function (args) {
        tooltip.close();
      },
      onEventDoubleClick: function (data, inst) {
        window.location.href = data.event.url;
      },
    });
    return calendar;
  }

}



