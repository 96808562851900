import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';
export default class extends Controller {
  static targets = ["theForm"]

  connect() {
    console.log("accommodation booking loaded")

    this.loadMobi()
    this.validateAccommodationBooking() 

  }

  loadMobi() {
    const orderStartDate = document.getElementById('temp_start_date')
    const orderEndDate = document.getElementById('temp_end_date')

    const startDate = document.getElementById('accommodation_booking_start_date')
    const endDate = document.getElementById('accommodation_booking_end_date')

    mobiscroll.datepicker('.datepicker', {
      controls: ['calendar'],
      select: 'range',
      dateFormat: 'DD/MM/YYYY',
      touchUi: true,
      showWeekNumbers: false,
      rangeHighlight: true,
      showRangeLabels: true,
      rangeStartLabel: 'First day',
      rangeEndLabel: 'Last day',
      display: 'anchored',
      showOnClick: true,
      showOnFocus: true,
      firstDay: 1,
      onInit: function (event, inst) {
        if (!startDate.value) {
          console.log("setting order dates as global")
          inst.setVal([orderStartDate.value, orderEndDate.value]);
          startDate.value = orderStartDate.value
          endDate.value = orderEndDate.value
        } else {
          inst.setVal([startDate.value, endDate.value]);
        }
      },
      onChange: function (event, inst) {
        startDate.value = event.value[0]
        endDate.value = event.value[1]
      }
    });
  }

  validateAccommodationBooking() {
    (() => {
      var form = this.theFormTarget

      const validation = new JustValidate(form, {
        errorFieldCssClass: 'is-invalid',
        errorLabelStyle: {
          marginTop: '0.25rem',
          fontSize: '10px',
          color: '#dc3545',
        },
        focusInvalidField: true,
        lockForm: true,
      });

      validation
        .addField('#accommodation_booking_participant_id', [
          {
            rule: 'required',
            errorMessage: 'Participant is required'
          }
        ])
        .addField('#accommodation_booking_accommodation_name', [
          {
            rule: 'required',
            errorMessage: 'Accommodation is required'
          }
        ])
        .addField('#accommodation_booking_date_range', [
          {
            rule: 'required',
            errorMessage: 'Date range is required'
          }
        ])
        .onSuccess((event) => {
          console.log('Validation passes and form submitted', event);
          form.submit()
        })
    })();
  }

}
