import { Controller } from "@hotwired/stimulus"
import JustValidate from 'just-validate';


// Connects to data-controller="validate"
export default class extends Controller {
  static targets = ["form"]

  connect() {
    console.log("validate controller connected")
    console.log("Form: " + this.formTarget.dataset.form)

    // find which form
    const form = this.formTarget.dataset.form

    // form scripts
    if (form == 'payment') { this.validatePayment() }
  }

  validatePayment() {
    const validation = new JustValidate('.payment_form');
    console.log(validation)

    validation
    .addField('#payment_payment_amount', [
      {
        rule: 'required',
        errorMessage: 'Payment amount is required'
      }
    ])
    .addField('#payment_payment_method_id', [
      {
        rule: 'required',
        errorMessage: 'Payment method is required'
      }
    ])
    .addField('#payment_payment_made', [
      {
        rule: 'required',
        errorMessage: 'Payment made is required'
      }
    ])
  }

}
